import "lazysizes";
import { normalizeVH, mobileHeight } from "./bundle/rcobiella-helpers.js";
import gsap, { Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { _debounce } from "lodash/debounce";
import Headroom from "headroom.js";

gsap.registerPlugin(ScrollTrigger);

mobileHeight();

var isMobile = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;

// window.onresize = () => {
// 	normalizeVH();
// };

/* -------------------------------------------------------------------------- */
/*                              LazySizes config                              */
/* -------------------------------------------------------------------------- */

window.lazySizesConfig = {
	loadMode: 3,
	expand: 1000,
};

(function ($) {
	var isMobile = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;

	/* ---------------------------------------------------------------- */
	/*                           Headroom.js                            */
	/* ---------------------------------------------------------------- */
	function initHeadroom() {
		var headerEl = document.querySelector("header.header");
		var headroom = new Headroom(headerEl, {
			tolerance: 10,
		});
		headroom.init();
	}
	initHeadroom();

	/* ---------------------------------------------------------------- */
	/*                 ScrollTrigger background change                  */
	/* ---------------------------------------------------------------- */

	// Previous function
	// Changes bg every time
	// function initScrollTriggerBackgrounds() {
	// 	const $modulos = $(".modulo");
	// 	if ($modulos.length) {
	// 		const mods = gsap.utils.toArray(".modulo.bg-switch");
	// 		mods.forEach((modulo) => {
	// 			gsap.to(modulo, {
	// 				scrollTrigger: {
	// 					trigger: modulo,
	// 					start: "top center",
	// 					onToggle: (self) => {
	// 						if (self.isActive) {
	// 							if ($(self.trigger).hasClass("bg-white")) {
	// 								changeBgWhite();
	// 							} else {
	// 								changeBgBlack();
	// 							}
	// 						}
	// 					},
	// 				},
	// 			});
	// 		});
	// 	}
	// }
	// function changeBgWhite() {
	// 	document.body.classList.add("force-modulos-white");
	// 	document.body.classList.remove("force-modulos-black");
	// }
	// function changeBgBlack() {
	// 	document.body.classList.add("force-modulos-black");
	// 	document.body.classList.remove("force-modulos-white");
	// }

	// New function
	// Switches only .bg-switch
	function initScrollTriggerBackgrounds() {
		const $modulos = $(".modulo");
		if ($modulos.length) {
			const mods = gsap.utils.toArray(".modulo.bg-switch");
			mods.forEach((modulo) => {
				gsap.to(modulo, {
					scrollTrigger: {
						trigger: modulo,
						start: "top 75%",
						onToggle: (self) => {
							if (self.isActive) {
								$(self.trigger).addClass("has-switched");
								// if ($(self.trigger).hasClass("bg-white")) {
								// 	changeBgWhite();
								// } else {
								// 	changeBgBlack();
								// }
							} else {
								$(self.trigger).removeClass("has-switched");
							}
						},
					},
				});
			});
		}
	}
	// init on load

	/* ---------------------------------------------------------------- */
	/*                       Add cursor to videos                       */
	/* ---------------------------------------------------------------- */
	function initVideoCursor() {
		const $videos = $("video");

		$("video").each(function () {
			// wrap them in container to apply icon on mobile
			let original = this;
			let wrapper = document.createElement("div");
			wrapper.classList.add("video-wrap");
			wrapper.appendChild(original.cloneNode(true));
			original.replaceWith(wrapper);
		});

		$("video").each(function () {
			const dis = this,
				parent = dis.closest(".video-wrap");
			if (!dis.autoplay) {
				dis.dataset.cursor = "play";
				parent.classList.add("has-cursor");

				if (dis.controls) {
					dis.classList.add("has-controls");
					parent.classList.add("has-controls");
					dis.controls = false;

					initVideoEvents(dis);
				}
			}
		});
	}

	initVideoCursor();

	// Initialize events to hide/show controls
	// and switch cursor to pause
	function initVideoEvents(dis) {
		const parent = dis.closest(".video-wrap");

		dis.addEventListener("mouseenter", function () {
			dis.controls = dis.paused ? false : true;
		});
		dis.addEventListener("mouseleave", function () {
			dis.controls = false;
		});
		dis.addEventListener("pause", function () {
			dis.classList.remove("is-playing");
			dis.classList.add("is-paused");
			parent.classList.remove("is-playing");
			parent.classList.add("is-paused");
			dis.dataset.cursor = "play";
			if (isMobile) {
				dis.controls = false;
			}
		});
		dis.addEventListener("play", function () {
			dis.classList.add("is-playing");
			dis.classList.remove("is-paused");
			dis.dataset.cursor = "pause";
			dis.classList.add("has-played");
			parent.classList.add("is-playing");
			parent.classList.remove("is-paused");
			parent.classList.add("has-played");
			if (isMobile) {
				dis.controls = true;
			}
		});
		dis.addEventListener("click", function (e) {
			// let controlz = dis.controls;
			// dis.controls = false;
			if (dis.paused) {
				dis.play();
				document.querySelector("#cursor").classList.add("pause");
				document.querySelector("#cursor").classList.remove("play");
			} else {
				document.querySelector("#cursor").classList.add("play");
				document.querySelector("#cursor").classList.remove("pause");
				// if (!dis.classList.contains("has-played")) {
				// 	dis.play();
				// }
				dis.pause();
			}
			e.preventDefault();
			return false;
			// dis.controls = controlz;
		});
	}

	/* ---------------------------------------------------------------- */
	/*         Move products header out of the header on mobile         */
	/* ---------------------------------------------------------------- */
	// Exec before load
	function mobileRepositionProductsHeader() {
		let productsHeader = document.querySelector(".header-products");
		if (productsHeader && isMobile) {
			productsHeader.classList.add("products-sticky");
			document.querySelector(".view-product").appendChild(productsHeader);

			// productsHeader.appendTo(".view-product").addClass("products-sticky");
		}
	}

	mobileRepositionProductsHeader();
	/* ---------------------------------------------------------------- */
	/*                             Pointer                              */
	/* ---------------------------------------------------------------- */
	function init_pointer2() {
		// Gets the mouse position
		const getMousePos = (e) => {
			return {
				x: e.clientX,
				y: e.clientY,
			};
		};
		// Linear interpolation
		const lerp = (a, b, n) => (1 - n) * a + n * b;

		//Grab mouse position and set it to mouse state
		let mouse = { x: 0, y: 0 };
		window.addEventListener("mousemove", (ev) => (mouse = getMousePos(ev)));

		class Cursor {
			constructor(el) {
				this.Cursor = el;
				// this.Follower = document.querySelector(".cursor-follow");
				this.Cursor.style.opacity = 0;

				this.cursorCnfigs = {
					x: { previous: 0, current: 0, amt: 0.2 },
					y: { previous: 0, current: 0, amt: 0.2 },
				};

				this.onMouseMoveEv = () => {
					this.cursorCnfigs.x.previous = this.cursorCnfigs.x.current =
						mouse.x;
					this.cursorCnfigs.y.previous = this.cursorCnfigs.y.current =
						mouse.y;

					// Set cursor opacity to 1 when hovered on the screen
					gsap.to(this.Cursor, {
						duration: 1,
						ease: "Power3.easeInOut",
						opacity: 1,
					});
					// gsap.to(this.Follower, {
					// 	duration: 1.2,
					// 	ease: "Power3.easeInOut",
					// 	opacity: 1,
					// });

					//    requestAnimationFrame
					requestAnimationFrame(() => this.render());

					//    Cleanup function
					window.removeEventListener("mousemove", this.onMouseMoveEv);
				};
				//    Assign the mouse function
				window.addEventListener("mousemove", this.onMouseMoveEv);
				const cursorDis = this;

				$("[data-cursor]").on(
					"mouseenter mouseleave mousedown mouseup",
					function (e) {
						const $dis = $(this);
						let cursorType = this.dataset.cursor || false;
						if (!cursorType) return false;

						let caption = this.dataset.caption || false;
						if (e.type == "mouseenter") {
							if (caption) {
								document.documentElement.style.setProperty(
									"--cursorCaption",
									`"${caption}"`
								);
							}
							cursorDis.Cursor.classList.add("is-hover");
							cursorDis.Cursor.classList.add(cursorType);
							// cursorDis.Follower.classList.add("is-hover");
						} else if (e.type == "mouseleave") {
							// cursorDis.Cursor.classList.remove("is-hover");
							// cursorDis.Follower.classList.remove("is-hover");
							cursorDis.Cursor.classList = "";
							if (caption) {
								document.documentElement.style.setProperty(
									"--cursorCaption",
									""
								);
							}
						} else if (e.type == "mousedown") {
							cursorDis.Cursor.classList.add("is-mousedown");
						} else if (e.type == "mouseup") {
							cursorDis.Cursor.classList.remove("is-mousedown");
						}
					}
				);
			}

			render() {
				this.cursorCnfigs.x.current = mouse.x;
				this.cursorCnfigs.y.current = mouse.y;
				for (const key in this.cursorCnfigs) {
					this.cursorCnfigs[key].previous = lerp(
						this.cursorCnfigs[key].previous,
						this.cursorCnfigs[key].current,
						this.cursorCnfigs[key].amt
					);
				}
				//    setting the cursor x and y to our cursor html element
				this.Cursor.style.transform = `translate3d(${this.cursorCnfigs.x.previous}px,${this.cursorCnfigs.y.previous}px, 0)`;
				// this.Follower.style.transform = `translate3d(${this.cursorCnfigs.x.previous}px,${this.cursorCnfigs.y.previous}px, 0)`;

				requestAnimationFrame(() => this.render());
			}
		}
		const cursor = new Cursor(document.querySelector("#cursor"));

		window.addEventListener("load", function () {
			// let c2 = document.querySelector(".cursor-follow"),
			let c1 = document.querySelector("#cursor");

			// gsap.to(c2, {
			// 	autoAlpha: 1,
			// 	duration: 1.5,
			// 	delay: 1.5,
			// });
			gsap.to(c1, {
				autoAlpha: 1,
				duration: 1.5,
				delay: 1.5,
			});
		});
	}
	if (!isMobile) {
		init_pointer2();
	}
	/* ---------------------------------------------------------------- */
	/*                             Sidebar                              */
	/* ---------------------------------------------------------------- */

	function initSidebar() {
		const $sidebar = $("#sidebar"),
			$wrapper = $sidebar.find(".wrapper");

		if (!$sidebar.length || !$wrapper.length) {
			return false;
		}

		// based on approximate product page optimal length
		let initial = parseInt((document.body.scrollHeight * 60) / 20000),
			initialY = `-${initial}%`;

		gsap.set($wrapper, {
			z: 0.01,
			y: initialY,

			onComplete: function () {
				gsap.to($wrapper, {
					scrollTrigger: {
						// trigger: document.body,
						start: 0,
						// end: "bottom bottom",
						end: () => `+=${document.body.offsetHeight}`,
						scrub: true,
						invalidateOnRefresh: true,
					},
					ease: "linear",
					z: 0.01,
					y: 0,
				});
			},
		});

		// Listen to event from faq.js
		function initScrollTriggerEventListener() {
			const event = new Event("refreshScrollTrigger");
			document.body.addEventListener(
				"refreshScrollTrigger",
				function () {
					// var delayedCallOne = gsap.delayedCall(0.4, function () {
					ScrollTrigger.refresh();
					// });
					// ScrollTrigger.refresh();
				},
				false
			);
		}
		initScrollTriggerBackgrounds();
		initScrollTriggerEventListener();
		// init observers
		// nada que ver con el sidebar
		initObservers();
	}
	window.addEventListener("load", initSidebar);

	/* ---------------------------------------------------------------- */
	/*                            Observers                             */
	/* ---------------------------------------------------------------- */

	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				// Check what type of entry it is
				let type = entry.target.dataset.observe || false;
				let params = entry.target.dataset.params || false;
				let lines = entry.target.dataset.lines || false;
				if (type == "text") {
					// initScramble(entry.target);
				} else if (type == "fade-up") {
					initFadeUp(entry.target, params);
				} else if (type == "fade-bg") {
					initFadeBg(entry.target, params);
				} else if (type == "float") {
					initFloatAnimation(entry.target);
				} else if (lines) {
					initLines(entry.target, params);
				}
			}
		});
	});

	function initObservers() {
		// Check for blockquotes
		// Disabled scramble, only fade-up
		let $blocks = $("blockquote");
		if ($blocks.length) {
			$blocks.each(function () {
				let dis = this,
					$p = $(this).find("p");

				$p.get(0).dataset.observe = "fade-up";
			});
		}

		// Check for scramble text
		// let $textAnims = $("[data-observe='text']");
		// if ($textAnims.length) {
		// 	$textAnims.each(function () {
		// 		let dis = this;
		// 		dis.style.display = "inline-block";
		// 		dis.style.height = `${dis.offsetHeight}px`;
		// 		dis.style.width = `${dis.offsetWidth}px`;
		// 		dis.style.overflow = "hidden";
		// 		observer.observe(dis);
		// 	});
		// }

		// Check for border animation
		let $borderAnims = $("[data-lines]");
		if ($borderAnims.length) {
			$borderAnims.each(function () {
				let dis = this;
				observer.observe(dis);
			});
		}
		// Check for float
		let $floatz = $("[data-observe='float']");
		if ($floatz.length) {
			$floatz.each(function () {
				let dis = this;
				observer.observe(dis);
			});
		}

		// Check for fade-ups
		let $fadeUps = $("[data-observe='fade-up']");
		if ($fadeUps.length) {
			$fadeUps.each(function () {
				let dis = this;
				observer.observe(this);
			});
		}

		// Check for fade-bg
		let $fadeBgs = $("[data-observe='fade-bg']");
		if ($fadeBgs.length) {
			$fadeBgs.each(function () {
				observer.observe(this);
				let img = this.querySelector("img");
				if (img) {
					img.classList.add("lazypreload");
				}
			});
		}
	}

	// Scramble text
	// function initScramble(entry) {
	// 	if (!entry || entry.dataset.init) return false;

	// 	let duration = entry.textContent.length * 0.02;

	// 	if (duration < 2.5) {
	// 		duration = 2.5;
	// 	}
	// 	gsap.to(entry, {
	// 		duration: duration,
	// 		scrambleText: {
	// 			text: "{original}",
	// 			chars: "ab cd ef gh ij kl mn ño pq rs tu vw xy z",
	// 			// revealDelay: 0.25,
	// 			tweenLength: false,
	// 		},
	// 	});

	// 	gsap.to(entry, {
	// 		duration: 1.5,
	// 		y: 0,
	// 		opacity: 1,
	// 		ease: Power1.easeOut,
	// 		onComplete: function () {
	// 			entry.dataset.init = true;
	// 			observer.unobserve(entry);
	// 		},
	// 	});
	// }

	// Marquee scrolltrigger
	function initMarqueeScroll() {
		const $marquees = $(".modulo.marquee-mod");

		if (!$marquees.length) return false;

		$marquees.each(function () {
			const $marquee = $(this).find(".marquee"),
				$inner = $marquee.find(".marquee-inner");

			gsap.set($inner, {
				z: 0.01,
				x: "-20%",
				autoAlpha: 0,
				onComplete: function () {
					gsap.to($inner, {
						duration: 1.5,
						ease: Power1.easeOut,
						autoAlpha: 1,
						x: "+=15%",
						onComplete: function () {
							gsap.to($inner, {
								ease: Power1.easeOut,
								scrollTrigger: {
									trigger: document.body,
									start: "top top",
									end: `+=${Math.round(
										$inner[0].getBoundingClientRect().y +
											$inner[0].offsetHeight +
											50
									)}`,
									scrub: true,
								},
								z: 0.01,
								x: 0,
								autoAlpha: 0,
							});
						},
					});
				},
			});
		});
	}
	initMarqueeScroll();

	/* ---------------------------------------------------------------- */
	/*                           Transitions                            */
	/* ---------------------------------------------------------------- */

	function initFadeUp(entry, float) {
		if (entry.dataset.init) return false;

		let delay = entry.dataset.delay || 0;

		if (delay < 0) {
			delay = 0;
		} else if (delay > 6) {
			delay = 6;
		}
		if (delay > 0) {
			let finalDelay = parseInt(delay) * 100 + "ms";
			entry.style.setProperty("--delay", finalDelay);
		}

		entry.dataset.init = true;
		observer.unobserve(entry);

		if (float) {
			initFloatAnimation(entry);
		}
	}

	// Float
	function initFloatAnimation(entry) {
		// tweenProperty(entry, "scale", 0.5, 4);
		tweenProperty(entry, "x", -2, 2);
		tweenProperty(entry, "y", -5, 9);

		function tweenProperty(target, prop, min, max) {
			var randomDur = gsap.utils.random(0.75, 1.25, 0.1, true);
			var randomDelay = gsap.utils.random(0.1, 0.2, 0.05, true);

			gsap.to(target, {
				[prop]: gsap.utils.random(min, max, 1),
				duration: randomDur(),
				// delay: randomDelay(),
				ease: "none",
				onComplete: tweenProperty,
				onCompleteParams: [target, prop, min, max],
			});
		}
	}

	// Image bg fade
	function initFadeBg(entry) {
		if (entry.dataset.init) return false;
		let image = entry.querySelector("img, video");

		if (!image) return false;

		let start = "top bottom-=" + window.innerHeight / 10 + "px";

		gsap.to(entry, {
			scrollTrigger: {
				trigger: entry,
				start: start,
				onToggle: (self) => {
					if (self.isActive) {
						entry.dataset.init = true;
						observer.unobserve(entry);
					}
				},
			},
		});
	}

	// Image lines animation
	function initLines(entry) {
		if (entry.dataset.init) return false;
		let start = "top bottom-=" + window.innerHeight / 10 + "px";
		gsap.to(entry, {
			scrollTrigger: {
				trigger: entry,
				start: start,
				onToggle: (self) => {
					if (self.isActive) {
						entry.dataset.init = true;
						observer.unobserve(entry);
					}
				},
			},
		});
	}

	document.body.scrollIntoView();

	/* ---------------------------------------------------------------- */
	/*                          ANIMACIÓN KNOB                          */
	/* ---------------------------------------------------------------- */

	const $imgKnob = $(".img-knob");
	$imgKnob.each(function () {
		const $dis = $(this),
			$img = $dis.find("img");
		if ($img.length) {
			gsap.to($img[0], {
				ease: Power1.easeOut,
				scrollTrigger: {
					trigger: $img,
					start: "top bottom",
					end: "+=" + window.innerHeight,
					scrub: true,
				},
				// z: 0.01,
				duration: 1,
				rotation: 360,
			});
		}
	});

	/* ---------------------------------------------------------------- */
	/*                            WHO WE ARE                            */
	/* ---------------------------------------------------------------- */
	// Add height value to hidden text
	// to then trigger animations
	$(window).on("resize", updateWhoWeAreHeights);
	function updateWhoWeAreHeights() {
		$(".who-text").each(function () {
			const $dis = $(this),
				height = this.scrollHeight,
				$parent = $dis.parents(".who-item");

			this.style.maxHeight = 0;
			$parent[0].style.setProperty("--height", `${height}px`);
		});
	}
	updateWhoWeAreHeights();

	/* ---------------------------------------------------------------- */
	/*                           Mobile menu                            */
	/* ---------------------------------------------------------------- */

	if (isMobile) {
		const $menuTrigger = document.querySelector(".trigger-menu-sm"),
			$menuIcon = $menuTrigger.querySelector(".icon-menu-sm"),
			$menuOverlay = $(".menu-overlay-sm");

		$menuTrigger.addEventListener("click", toggleMobileMenu);

		function toggleMobileMenu() {
			$menuTrigger.classList.toggle("is-open");

			if ($menuTrigger.classList.contains("is-open")) {
				$menuOverlay.fadeIn(function () {
					// document.body.classList.add("menu-is-open");
				});
				setTimeout(function () {
					document.body.classList.add("menu-is-open");
				}, 100);
				$("body").addClass("no-scroll");
			} else {
				$menuOverlay.fadeOut();
				$("body").removeClass("no-scroll menu-is-open");
			}
		}
	}
	/* -------------------------------------------------------------------------- */
	/*                              isMobile & TOUCH                              */
	/* -------------------------------------------------------------------------- */

	const TOUCH = "ontouchstart" in window;

	var isMobile = TOUCH ? true : false;
	var $cursor;

	/* -------------------------------------------------------------------------- */
	/*                                Loader + AOS                                */
	/* -------------------------------------------------------------------------- */

	let ignore_unload = false;

	// Prevent if link has mailto
	$("a[href^=mailto], a[href^=tel]").on("click", function () {
		ignore_unload = true;
	});

	$(window).on("beforeunload", function (e) {
		if (!ignore_unload) {
			$("#loader").removeClass("is-loaded");
		}
		ignore_unload = false;
	});

	window.addEventListener("load", function () {
		setTimeout(function () {
			let hash = location.hash;
			if (hash) {
				hash = hash.replace("#", "");
				let scrollToNode = document.getElementById(hash);
				if (scrollToNode) {
					window.scrollTo({
						top: scrollToNode.offsetTop,
						behavior: "auto",
					});
				}
			}

			// Remove preload-transitions class from body to re-enable transitions
			$("body").removeClass("preload-transitions");
			$("#loader").addClass("is-loaded");
		}, 0);
	});
})(jQuery);

window.addEventListener("load", function () {
	window.document.body.classList.remove("preload-transitions");
	window.document.getElementById("loader").classList.add("is-loaded");
});
