// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
/**
 *
 * Debounce
 * debounce(function, wait, immediate)
 *
 * @param {function} Función
 * @param {int} wait Tiempo entre ejecuciones
 * @param {bool} immediate TRUE para ejecutar inmediatamente
 *
 */
export function debounce(func, wait, immediate) {
	let timeout;
	return function () {
		let context = this,
			args = arguments;
		let later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export function scrollToTop() {
	let scrollTopEls = document.querySelector(".scroll-to-top");
	scrollTopEls.onclick = function () {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
}

/**
 *
 * Valida un email dado
 * @param {string} mail - Email
 * @return {bool} - True o false
 *
 */
export const validateEmail = (mail) => {
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
		return true;
	}
	return false;
};

/**
 *
 * Returns variable value from current url, or false
 * @param {string} variable - Variable to look for
 * @return {string,bool} - Value or false
 *
 */

export function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return false;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Add one or more listeners to an element */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

export function addListenerMulti(el, s, fn) {
	s.split(" ").forEach((e) => el.addEventListener(e, fn, false));
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Normalize viewport height */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

export function normalizeVH() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	document.addEventListener("DOMContentLoaded", normalizeVH);
	document.addEventListener("resize", normalizeVH);
}

export const mobileHeight = () => {
	const TOUCH = "ontouchstart" in window;
	const setDocHeight = () => {
		document.documentElement.style.setProperty(
			"--vh",
			`${window.innerHeight / 100}px`
		);
	};

	if (!TOUCH) {
		window.addEventListener("resize", function () {
			setDocHeight();
		});
	}
	window.addEventListener("orientationchange", function () {
		setDocHeight();
	});
	window.addEventListener("DOMContentLoaded", () => {
		setDocHeight();
	});
};

/* -------------------------------------------------------------------------- */
/*                                  Scroll to                                 */
/* -------------------------------------------------------------------------- */

export function smoothScroll() {
	const scrollToButtons = document.querySelectorAll(".scroll-to");
	if (scrollToButtons.length) {
		scrollToButtons.forEach((el) => {
			el.addEventListener("click", () => {
				let scrollTo = el.dataset.to || false;
				if (!scrollTo) return;
				// Si es un número, scroll
				if (Number.isInteger(scrollTo) || scrollTo === "0") {
					window.scroll({
						top: scrollTo,
						left: 0,
						behavior: "smooth",
					});
				} else {
					// Si no, scroll al elemento
					window.scroll({
						top: document.querySelector(scrollTo).offsetTop,
						left: 0,
						behavior: "smooth",
					});
				}
			});
		});
	}
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Define REM */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

export function convertRemToPixels(rem) {
	return (
		rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
	);
}

/**
 * Limita un número al rango min, max
 * @param {Number} value - Valor a procesar
 * @param {Number} min - Valor mínimo
 * @param {Number} max - Valor máximo
 * @return {Number} Valor acotado de 'value' entre 'min' y 'max'
 */
export const clamp = (value, min, max) => {
	return Math.min(Math.max(value, min), max);
};
